import React, { useCallback, useMemo } from "react";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import ProMatchesList from "@/game-lol/components/ProMatchesList.jsx";
import RolesButtons from "@/game-lol/components/RolesButtons.jsx";
import { FILTER_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import Static from "@/game-lol/utils/static.mjs";
import { getDefaultedFiltersForProBuilds } from "@/game-lol/utils/util.mjs";
import ExclamationIcon from "@/inline-assets/exclamation-mark.svg";
import parseSearchParams from "@/util/parse-search-params.mjs";
import { useTransientRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export const PlaceholderContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--sp-22) * 3);

  svg {
    width: 7rem;
    height: 7rem;
  }
`;

const navigateToRoute = (urlParams) => {
  const path = router?.route?.currentPath;
  updateRoute(path, urlParams);
};

const History = () => {
  const state = useSnapshot(readState);
  const { searchParams } = useTransientRoute();
  const defaultFilter = getDefaultedFiltersForProBuilds(searchParams);

  const filter = useMemo(() => {
    return {
      role: defaultFilter.role,
      team: defaultFilter.team,
    };
  }, [defaultFilter]);

  const doFilter = useCallback((filterParams) => {
    if (!filterParams.role) delete filterParams.role;
    if (!filterParams.team) delete filterParams.team;
    const urlParams = parseSearchParams(filterParams);
    navigateToRoute(urlParams);
  }, []);

  const setFilter = useCallback(
    (key, val) => {
      switch (key) {
        case FILTER_SYMBOLS.role:
          doFilter({ ...filter, role: val });
          break;
        case FILTER_SYMBOLS.team:
          doFilter({ ...filter, team: val });
          break;
      }
    },
    [filter, doFilter],
  );

  const {
    lol: { proBuildTeams, proBuildMatchlist: matchlist },
  } = state;

  const loading = !proBuildTeams || !matchlist;

  const filteredTeams = proBuildTeams.filter((team) => {
    return team.region && team.players?.length >= 5;
  });

  const teams = [
    {
      id: null,
      name: "All Teams",
      pictureUrl: null,
    },
    ...filteredTeams,
  ].map((team) => ({
    value: team.id,
    text: [`lol:proTeam_${team.id || "all"}`, team.name],
    image: team.pictureUrl && Static.getProTeamImage(team.pictureUrl),
  }));

  const filters = (
    <div className="flex wrap gap-sp-2">
      <RolesButtons
        withName
        selected={filter.role}
        onClick={(v) => {
          setFilter(FILTER_SYMBOLS.role, v);
        }}
      />
      {teams.length ? (
        <Select
          selected={filter.team}
          options={teams}
          onChange={(val) => setFilter(FILTER_SYMBOLS.team, val)}
          inputStyle
        />
      ) : null}
    </div>
  );

  if (loading) {
    return (
      <>
        {filters}
        <Card loading style={{ height: 1000 }} />
      </>
    );
  }

  const hasError = proBuildTeams instanceof Error || matchlist instanceof Error;

  if (hasError) {
    return (
      <>
        {filters}
        <Card>
          <PlaceholderContainer>
            <ExclamationIcon />
          </PlaceholderContainer>
        </Card>
      </>
    );
  }

  return (
    <>
      {filters}
      <Card>
        <ProMatchesList matches={matchlist} />
      </Card>
    </>
  );
};

export default History;

import React from "react";
import { useTranslation } from "react-i18next";

import History from "@/game-lol/components/ProBuildsHistory.jsx";
import { getCurrentPatchForStaticData } from "@/game-lol/utils/util.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const ProBuilds = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PageHeader title={t("lol:tab.probuilds", "Pro Builds")} />
      <History />
    </PageContainer>
  );
};

export default ProBuilds;

export function meta() {
  return {
    title: ["lol:meta.probuilds.title2", "Probuilds from Top LoL Pros"],
    description: [
      "lol:meta.probuilds.mdescription2",
      "Discover builds from the world's best League of Legends players. All probuilds are updated in Real Time.",
    ],
    subtitle: [
      "lol:meta.probuilds.subtitle",
      "Discover the latest League of Legends builds the pros are using in the {{patch}} meta up to the minute. Win more games more often by emulating the best.",
      { patch: getCurrentPatchForStaticData() },
    ],
  };
}
